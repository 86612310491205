import LayoutAside from '@/components/layout/LayoutAside';
import LayoutBodyAside from '@/components/layout/LayoutBodyAside';
import React, { useEffect, Suspense } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { hideNavState } from '@/atoms/navState';
import { authState } from '@/atoms/authState';
import Loading from '@/components/loading/Loading';
import { styledLayoutWrap } from '@/components/layout/styleOfLayout';
import { mobileSizeState } from '@/atoms/mobileSizeState';
import { isMobileState } from '@/atoms/isMobileState';

export interface TAppLayoutProps extends React.HTMLAttributes<HTMLDivElement> {}

function AppLayout(props: TAppLayoutProps) {
  const [mobileSize, setMobileSize] = useRecoilState(mobileSizeState);
  const [isMobile] = useRecoilState(isMobileState);
  const [hideNav, setHideNav] = useRecoilState(hideNavState);

  const [authInfo] = useRecoilState(authState);

  const { isAuthStateLoaded, isLogged } = authInfo;

  const navigate = useNavigate();
  const matchRoot = useMatch('/');

  const { children, className, ...rest } = props;

  useEffect(() => {
    if (isAuthStateLoaded && !isLogged) {
      navigate('/sign-in', { replace: true });
    }

    if (isLogged && matchRoot) {
      navigate('/home', { replace: true });
    }
  }, [matchRoot, isAuthStateLoaded, isLogged, navigate]);

  /** 창크기 변경시 - 모바일 사이즈 확인 **/
  useEffect(() => {
    const handleResize = debounce(() => {
      const itMobileSize = window.innerWidth < 768;
      setMobileSize(itMobileSize);

      setHideNav(itMobileSize);
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => {
      // cleanup
      window.removeEventListener('resize', handleResize);
    };
  }, [setHideNav, setMobileSize]);

  const clickGnb = () => {
    setHideNav(!hideNav);
  };

  const [auth] = useRecoilState(authState);

  if (!auth.isAuthStateLoaded) {
    return <Loading />;
  }

  return (
    <div
      className={classNames(`wrap-layout flex ${className || ''}`, {
        'mobile': mobileSize || isMobile,
      })}
      css={styledLayoutWrap}
      {...rest}
    >
      {isLogged && (
        <Suspense fallback={<Loading />}>
          <LayoutAside isHidden={hideNav} mobileSize={mobileSize || isMobile} clickGnb={clickGnb} />
        </Suspense>
      )}

      <Outlet />
    </div>
  );
}

export { LayoutBodyAside as BodyAside };
export default AppLayout;
