import React, { useCallback, useState } from 'react';
import { Form } from 'antd';
import Modal from '@/components/popup/Modal';
import messageInForm from '@/assets/messageForm';
import InputPassword from '@/components/inputPassword/InputPassword';

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface TModalToChangePasswordProps {
  visible: boolean;
  onOk: (values: Values) => void;
  onCancel: () => void;
  loading?: boolean;
}

const ModalToChangePassword: React.FC<TModalToChangePasswordProps> = ({
  visible,
  onOk,
  onCancel,
  loading,
}) => {
  const [form] = Form.useForm();

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleOkModal = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onOk(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  }, [form, onOk]);

  const handleCancelModal = useCallback(() => {
    onCancel();
    form.resetFields();
  }, [onCancel, form]);

  return (
    <Modal
      visible={visible}
      data-visible={visible}
      width="340px"
      title="비밀번호 수정"
      okText="확인"
      cancelText="취소"
      onCancel={handleCancelModal}
      onOk={handleOkModal}
      loading={loading}
      isButtonDisabled={buttonDisabled}
    >
      <p>새로운 비밀번호를 입력해주세요.</p>
      <Form
        form={form}
        name="formInModal"
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        data-testid="checkPassword"
      >
        <Form.Item
          name="password"
          rules={[
            {
              pattern: new RegExp(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&~])[A-Za-z\d@$!%*#?&~]{8,}$/,
              ),
              message: messageInForm.password2.error2,
            },
            { required: true, message: messageInForm.password2.error },
          ]}
          className="mt-[2.4rem]"
        >
          <InputPassword allowClear={true} placeholder={messageInForm.password2.placeholder} />
        </Form.Item>

        <Form.Item
          name="password2"
          dependencies={['password']}
          validateFirst="parallel"
          rules={[
            { required: true, message: messageInForm.password3.error },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const reg = new RegExp(
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                );
                if (!value || (getFieldValue('password') === value && reg.test(value))) {
                  setButtonDisabled(false);
                  return Promise.resolve();
                }
                setButtonDisabled(true);
                return Promise.reject(new Error(messageInForm.password3.error2));
              },
            }),
          ]}
          className="mt-[2.8rem]"
        >
          <InputPassword allowClear={true} placeholder={messageInForm.password3.placeholder} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalToChangePassword;
