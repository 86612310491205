import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TButtonProps } from './Button';
import theme from '@/assets/styles/theme';
import { Button } from 'antd';

export const StyledButton = styled(Button)((props: { design: TButtonProps['design'] }) => {
  const { design } = props;

  let styledButton = ``;

  switch (design) {
    case 'primary':
      styledButton = styledPrimary;
      break;
    case 'secondary':
      styledButton = styledSecondary;
      break;
    case 'tertiary':
      styledButton = styledTertiary;
      break;
    case 'quaternary':
      styledButton = styledQuaternary;
      break;
    case 'icon':
      styledButton = styledIcon;
      break;
    case 'modal1':
      styledButton = styledModal1;
      break;
    case 'modal2':
      styledButton = styledModal2;
      break;
    case 'form1':
      styledButton = styledForm1;
      break;
  }

  return css`
    border-radius: 0.6rem;
    border: none !important;
    box-shadow: none !important;
    transition: none !important;

    ${styledButton}
    // antd - loading시 노출되지 않도록 추가
    &:before {
      display: none;
    }

    // antd - 버튼 클릭시 노출되지 않도록 추가
    &:after {
      display: none;
    }

    .ant-btn-loading-icon {
      position: absolute !important;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .anticon {
        padding: 0;
      }
    }

    &.ant-btn-sm {
      font-size: 12px;
      min-height: 36px;
    }
  `;
});

export const styledPrimary = `
  min-height: 4.8rem;
  padding: 0 1.4rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 128.9%;
  color: #fff !important;
  background-color: #3462FF;
  border: none !important;
  
  &:focus {
    color: #fff !important;
    background-color: #3462FF;
  }
  
  &:hover {
    background-color: #003AFF;
  }
  
  &:active {
    background-color: #2A4ECC;
  }
  
  &:disabled {
    color: rgba(255, 255, 255, .5) !important;
    background-color: #96ADFC !important;
  }

`;

export const styledSecondary = `
  min-height: 4rem;
  padding: 0 .8rem;
  border: 1px solid ${theme.color.line} !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 128.9%;
  color: #474E60 !important;
  background: #FFFFFF;
  
  &:focus {
    color: #191919 !important;
    background: #FFFFFF;
  }
  
  &:hover {
    background-color: #F3F3F3;
  }
  
  &:active {
    background-color: #E9E9E9;
  }
`;

export const styledTertiary = `
  padding: 0 1rem;
  border: none !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 128.9%;
  color: #000;
  background-color: transparent;
  
  &:focus {
    color: #000;
    background-color: transparent;
  }
  
  &:hover {
    color: inherit;
    background-color: transparent;
  }
  
  &:active {
    color: inherit;
    background-color: transparent;
  }
`;

export const styledTertiaryOther = css`
  color: ${theme.color.secondary.default};
  &:focus {
    color: ${theme.color.secondary.default};
  }

  &:active,
  &:hover {
    color: ${theme.color.secondary.default};
  }
`;

export const styledQuaternary = `
  height: 4rem;
  padding: 0 1rem;
  border: 1px solid ${theme.color.primary.default} !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 128.9%;
  color: ${theme.color.primary.default};
  background-color: transparent;
  
  &:focus,
  &:hover,
  &:active {
    color: ${theme.color.primary.default};
    background-color: transparent;
  }
`;

export const StyledWrapTertiary = styled.div`
  > span {
    padding: 0 10px;
    text-align: center;

    button {
      height: 100%;
    }

    &:not(:nth-of-type(1)) {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 1rem;
        background-color: ${theme.color.line};
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export const styledIcon = `
  min-height: 3.2rem;
  padding: 0 .9rem;
  line-height: 2rem;
  background: #FFFFFF;
  
  &:disabled {
    background-color: transparent !important;
    svg path {
    fill: #B2BECF;
    }
  }
  
  
  &:focus {
    background: #FFFFFF;
  }
  
  &:hover {
    background-color: #F3F3F3;
  }
  
  &:active {
    background-color: #E9E9E9;
  }
  
`;

export const styledModal1 = `
  min-height: 4.8rem;
  padding: 0 .8rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 128.9%;
  color: #FFFFFF !important;
  background: #474E60;
  
  &:disabled {
    background: #D1D5DE;
  }
  
  &:focus {
    color: #FFFFFF !important;
    background: #474E60;
  }
    
  
  &:hover {
    background-color: #566078;
  }
  
  &:active {
    background-color: #3B404E;
  }
`;

export const styledModal2 = `
  min-height: 4.8rem;
  padding: 0 .8rem;
  border: 1px solid #474E60 !important;
  font-weight: 700;
  font-size: 14px;
  color: #474E60 !important;
  line-height: 128.9%;
  background: #FFFFFF;
  
  &:focus {
    color: #191919 !important;
    background: #FFFFFF;
  }
  
  &:hover {
    background-color: #F3F3F3;
  }
  
  &:active {
    background-color: #E9E9E9;
  }
`;

export const styledForm1 = `
  height: 36px;
  background: #474E60;
  border-radius: 6px;
  color: #FFFFFF !important;
  
  &:focus {
    color: #FFFFFF !important;
    background: #474E60;
  }
    
  
  &:hover {
    background-color: #566078;
  }
  
  &:active {
    background-color: #3B404E;
  }
`;
