import React, { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import { ButtonProps } from 'antd/lib/button/button';
import { StyledButton } from './styleOfButton';

export interface TButtonProps extends ButtonProps {
  design?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'icon'
    | 'modal1'
    | 'modal2'
    | 'form1';
}

function Button(props: TButtonProps, ref: ForwardedRef<any>) {
  const { design = 'primary', icon: Img, children, className, ...rest } = props;

  return (
    <StyledButton
      design={design}
      {...rest}
      className={classNames(`${className || ''}`, {
        'flex items-center': Img,
      })}
      ref={ref}
    >
      {Img && <span className="icon">{Img}</span>}
      {children}
    </StyledButton>
  );
}

export default forwardRef(Button);
