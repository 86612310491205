const messageInForm = {
  email: {
    placeholder: "이메일을 입력해주세요.",
    error: "이메일 형식으로 입력해주세요.",
    error2: "고객님의 이메일을 입력해주세요.",

  },
  password: {
    placeholder: "비밀번호를 입력해주세요.",
    error: "비밀번호를 입력해주세요.",
  },
  password2: {
    placeholder: "8자 이상 영문, 숫자, 특수문자 포함",
    error: "비밀번호를 입력해주세요.",
    error2: "비밀번호는 8자 이상 영문, 숫자, 특수문자를 포함해주세요."
  },
  password3: {
    placeholder: "비밀번호 확인",
    error: "비밀번호를 한번 더 입력해주세요.",
    error2: "동일한 비밀번호를 입력해주세요.",
    error3: "비밀번호는 8자 이상 영문, 숫자, 특수문자를 포함해주세요."
  },
  name: {
    placeholder: "한글 또는 영문만 가능",
    error: "고객님의 이름을 입력해주세요.",
    error2: "한글 또는 영문으로 2자 이상 입력해주세요.",
  },
}
export default messageInForm;
