import React from 'react';
import Modal from '@/components/popup/Modal';

interface AccountErrorModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  title: string;
  content: string | React.ReactElement;
}

function AccountErrorModal({ visible, onOk, onCancel, title, content }: AccountErrorModalProps) {
  if (!visible) {
    return null;
  }

  return (
    <Modal visible={visible} title={title} okText="확인" onOk={onOk} onCancel={onCancel}>
      <p>{content}</p>
    </Modal>
  );
}

export default AccountErrorModal;
