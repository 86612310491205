import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import IconHome from '@/assets/images/gnb/icon-home.svg';
import IconLibrary from '@/assets/images/gnb/icon-library.svg';
import iconUseState from '@/assets/images/gnb/icon-use-state.svg';
import ImgTrash from '@/assets/images/gnb/icon-trash.svg';
import IconInquiryEmail from '@/assets/images/gnb/icon-Inquiry-email.svg';
import IconTermsAndConditions from '@/assets/images/gnb/icon-terms-and-conditions.svg';
import IconMyPage from '@/assets/images/gnb/icon-my-page.svg';
import IconNotice from '@/assets/images/gnb/icon-notice.svg';
import GnbItem from '@/components/layout/aside/GnbItem';
import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';
import classNames from 'classnames';

const gngData = [
  { name: '홈', icon: IconHome, to: '/home' },
  { name: '모든 자막', icon: IconLibrary, to: '/notes' },
];

const gngData2 = [
  { name: '사용현황', icon: iconUseState, to: '/usage-state' },
  { name: '휴지통', icon: ImgTrash, to: '/trash-notes' },
  { name: '문의메일', icon: IconInquiryEmail, to: 'mailto:contact@sovoro.kr' },
  { name: '서비스정보', icon: IconTermsAndConditions, to: '/terms-and-conditions' },
  { name: '마이페이지', icon: IconMyPage, to: '/account' },
  { name: '공지사항', icon: IconNotice, to: '/notice' },
];

const GlobalNavigation = () => {
  const location = useLocation();
  const [currentPathname, setCurrentPathname] = useState(location.pathname);

  useEffect(() => {
    setCurrentPathname(location.pathname);
  }, [location]);

  return (
    <>
      <ul>
        {gngData.map((item) => {
          return (
            <GnbItem
              key={item.name}
              to={item.to}
              name={item.name}
              icon={item.icon}
              className={classNames({ 'is-active': currentPathname.includes(item.to) })}
            />
          );
        })}
      </ul>

      <ul css={styledGnbBottom}>
        {gngData2.map((item) => {
          return (
            <GnbItem
              key={item.name}
              to={item.to}
              name={item.name}
              icon={item.icon}
              className={classNames({ 'is-active': currentPathname.includes(item.to) })}
            />
          );
        })}
      </ul>
    </>
  );
};
export default GlobalNavigation;

const styledGnbBottom = css`
  &:before {
    content: '';
    display: block;
    margin: 1.4rem 1rem 1.6rem;
    border-top: 1px solid ${theme.color.line};
  }
`;
