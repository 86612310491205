import { Theme } from '@emotion/react';

const calcRem = (size: number) => `${size / 16}rem`;

/* src/assets/styles/emotion.d.ts 에 타입 정의 필요함 */
const color = {
  default: '#000000',
  primary: {
    default: '#3462FF',
    disabled: '#3462FF',
    hover: '#003AFF',
  },
  secondary: {
    default: '#A1A1A1',
  },
  tertiary: {
    default: '#8E8E8E',
  },
  error: {
    text: '#E84562',
  },
  line: '#D5DDE7',
  line2: '#ECEFF2',
  line3: '#B0B8C1',
};

const formColor = {
  default: '#D5DDE7',
  focus: '#3462FF',
  checked: '#3462FF',
  error: '#E83232',
  icon: '#B0B8C1',
};
const formRadius = {
  input: '6px',
  checkbox: '4px',
};

const boxShadow = {
  normal: '0 3px 8px 0 rgb(0 0 0 / 10%)',
  purple: '0 3px 8px 0 #d6c9ff',
  blue: '0 3px 8px 0 #b3e2e6',
};

const padding = {
  small: calcRem(8),
  base: calcRem(10),
  lg: calcRem(12),
  xl: calcRem(14),
  xxl: calcRem(16),
  xxxl: calcRem(18),
};

const theme: Theme = {
  color,
  boxShadow,
  padding,
  formColor,
  formRadius,
};

export default theme;
