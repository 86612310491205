import { useMutation } from '@tanstack/react-query';
import { MutationFunction } from '@tanstack/query-core';
import { messageInfo } from '@/components/popup/message';
import { useNavigate } from 'react-router-dom';

export default function useMutationWithMessage<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(mutationFn: MutationFunction<TData, TVariables>, message: string) {
  const navigate = useNavigate();

  return useMutation<TData, TError, TVariables, TContext>(mutationFn, {
    onSuccess: async () => {
      await messageInfo({
        content: message,
        noIcon: true,
      });

      await navigate('/notes');
    },
  });
}
