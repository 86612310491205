import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';

export const antdStyleForm = css`
  //.ant-row ant-form-item ant-form-item-with-help ant-form-item-has-error
  .ant-form-item {
    //background-color: aqua;
  }

  .ant-form-item-with-help {
    .ant-form-item-extra,
    .ant-form-item-explain {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      margin-top: 0.6rem;
      font-size: 1.2rem;
      text-align: center;
      line-height: 128.9%;
      transition: none !important;
    }

    .ant-form-item-explain-error {
      color: ${theme.color.error.text};
    }
  }

  .ant-form-item-label {
    font-weight: bold;
  }
`;
