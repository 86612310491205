import classNames from 'classnames';
import { Link } from 'react-router-dom';
import React from 'react';
import logo from '@/assets/images/logo-sovoro.svg';
import ButtonToCloseGnb from '@/components/layout/aside/ButtonToCloseAside';
import GlobalNavigation from '@/components/layout/aside/GlobalNavigation';
import {
  styledLayoutAside,
  styledLayoutAsideDim,
  styledLayoutGnb,
  styledLayoutHeader,
} from '@/components/layout/styleOfLayout';

interface TInternalProps extends React.HTMLAttributes<HTMLElement> {
  isHidden?: boolean;
  mobileSize: boolean;
  clickGnb?: () => void;
}

const LayoutAside = ({ isHidden = false, mobileSize, clickGnb = () => {} }: TInternalProps) => {
  return (
    <>
      <aside
        className={classNames(
          `flex flex-col flex-none ${mobileSize ? 'transition mobile' : 'transition-[margin]'}`,
          { isHidden: isHidden },
        )}
        css={styledLayoutAside}
      >
        <header className="flex-none" css={styledLayoutHeader}>
          <div className="flex justify-between">
            <Link to="/">
              <img src={logo} alt="sovoro 로고" width={102} height={24} />
            </Link>
            <ButtonToCloseGnb className="btn-gnb-close flex-none" onClick={clickGnb} />
          </div>
        </header>
        <nav className="flex-1" css={styledLayoutGnb}>
          <GlobalNavigation />
        </nav>
      </aside>

      {/* 모바일 - dim 영역 클릭시 메뉴 영역 닫힘 */}
      {!isHidden && mobileSize && <div css={styledLayoutAsideDim} onClick={clickGnb} />}
    </>
  );
};
export default LayoutAside;
