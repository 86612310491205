import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/lib/auth/firebase-auth';
import { useSetRecoilState } from 'recoil';
import { authState } from '@/atoms/authState';

export default function useAuthStateChanged() {
  const setAuthState = useSetRecoilState(authState);

  onAuthStateChanged(auth, async (user: any) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User

      setAuthState({
        isAuthStateLoaded: true,
        isLogged: true,
        isPersistent: user.auth.persistenceManager.persistence.type === 'LOCAL' ? true : false,
        email: user.email,
        displayName: user.displayName,
        emailVerified: user.emailVerified,
      });
    } else {
      // User is signed out
      // ...
      setAuthState({
        isAuthStateLoaded: true,
        isLogged: false,
        isPersistent: false,
      });
    }
  });
}
