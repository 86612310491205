import React, { useCallback, useState } from 'react';
import SEOMetaTag from '@/components/SEOMetaTag';
import PageHeader from '@/components/layout/PageHeader';
import Button from '@/components/button/Button';
import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';
import AppBodyLayout from '@/components/app/AppBodyLayout';
import { useRecoilState } from 'recoil';
import { authState } from '@/atoms/authState';
import { auth } from '@/lib/auth/firebase-auth';
import { useNavigate } from 'react-router-dom';
import Modal from '@/components/popup/Modal';
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import AccountErrorModal from '@/pages/Account/AccountErrorModal';
import useWithdrawMutation from '@/pages/Account/hooks/useWithdrawMutation';
import ModalToCheckPassword from './ModalToCheckPassword';
import ModalToChangePassword from './ModalToChangePassword';
import ModalToWithdrawal from './ModalToWithdrawal';

function Account() {
  const navigate = useNavigate();

  const { mutate } = useWithdrawMutation();

  const [authInfo] = useRecoilState(authState);
  const { displayName, email } = authInfo;

  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
    content: string | React.ReactElement;
  }>({
    title: '',
    content: '',
  });

  const [loading, setLoading] = useState(false);

  const closeErrorModal = () => {
    setErrorModalVisible(false);
  };

  const signOut = async () => {
    await auth.signOut();
    await navigate('/sign-in', { replace: true });
  };

  /* [팝업] 비밀번호 확인 */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleShowModal = () => {
    setIsModalVisible(true);
  };
  const handleOkModal = useCallback(
    async (values: any) => {
      const { password } = values;

      setLoading(true);

      try {
        if (email) {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);

          setLoading(false);

          setIsModalVisible(false);

          setIsModalVisible2(true);
        }
      } catch (error: any) {
        if (error.code === 'auth/wrong-password') {
          setErrorMessage({
            title: '패스워드를 확인해주세요.',
            content: '고객님의 정보를 다시 확인하시고 입력해주세요.',
          });
        } else if (error.code === 'auth/too-many-requests') {
          setErrorMessage({
            title: '잠시 후 다시 시도해주세요.',
            content: (
              <>
                비정상적인 활동으로 차단되었습니다.
                <br />
                잠시 후 다시 시도하면 차단이 해제됩니다.
              </>
            ),
          });
        } else {
          setErrorMessage({
            title: '알 수 없는 오류입니다.',
            content: '잠시 후 다시 시도해주세요.',
          });
        }
        setLoading(false);
        setIsModalVisible(false);
        setErrorModalVisible(true);
      }
    },
    [email],
  );
  const handleCancelModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  /* [팝업] 비밀번호 수정 */
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const handleOkModal2 = useCallback(async (values: any) => {
    const { password } = values;

    setLoading(true);

    try {
      if (auth.currentUser) {
        await updatePassword(auth.currentUser, password);

        setLoading(false);

        setIsModalVisible2(false);
        setIsModalVisible3(true);
      }
    } catch (error) {
      setLoading(false);
      setIsModalVisible2(false);
      setErrorMessage({
        title: '알 수 없는 오류입니다.',
        content: '잠시 후 다시 시도해주세요.',
      });
    }
  }, []);
  const handleCancelModal2 = useCallback(() => {
    setIsModalVisible2(false);
  }, []);

  /* [팝업] 비밀번호 수정 완료 */
  const [isModalVisible3, setIsModalVisible3] = useState(false);

  const handleOkModal3 = useCallback(async () => {
    setIsModalVisible3(false);
    await auth.signOut();
    navigate('/sign-in');
  }, [navigate]);
  const handleCancelModal3 = useCallback(async () => {
    setIsModalVisible3(false);
    await auth.signOut();
    navigate('/sign-in');
  }, [navigate]);

  const [withdrawalModalVisible, setWithdrawalModalVisible] = useState(false);
  const handleShowWithdrawalModal = () => {
    setWithdrawalModalVisible(true);
  };
  const handleOkWithdrawalModal = useCallback(
    (values: any) => {
      setLoading(true);
      mutate({
        reason: values.reason,
        content: values.content,
      });

      setWithdrawalModalVisible(false);
      setLoading(false);
      auth.signOut();
      navigate('/sign-in', { replace: true });
    },
    [navigate, mutate],
  );
  const handleCancelWithdrawalModal = useCallback(() => {
    setWithdrawalModalVisible(false);
  }, []);

  return (
    <>
      <SEOMetaTag title="마크업 > 마이페이지" />
      <AppBodyLayout
        pageHeader={
          <PageHeader
            header={<h1 className="title text-ellipsis overflow-hidden">마이페이지</h1>}
          />
        }
      >
        <div css={styleAccountInfo}>
          <dl>
            <dt className="title">이름</dt>
            <dd className="description">{displayName}</dd>
          </dl>
          <dl>
            <dt className="title">계정</dt>
            <dd className="description">{email}</dd>
          </dl>
        </div>

        <div css={styleAccountWrapButtons}>
          <span>
            <Button design="tertiary" onClick={handleShowModal}>
              비밀번호 변경
            </Button>
          </span>
          <span>
            <Button design="tertiary" onClick={signOut}>
              로그아웃
            </Button>
          </span>
          <span>
            <Button design="tertiary" onClick={handleShowWithdrawalModal}>
              회원탈퇴
            </Button>
          </span>
        </div>
      </AppBodyLayout>
      {/* [팝업] 비밀번호 확인 */}
      {isModalVisible && (
        <ModalToCheckPassword
          visible={isModalVisible}
          onOk={handleOkModal}
          onCancel={handleCancelModal}
          loading={loading}
        />
      )}

      {/* [팝업] 비밀번호 수정 */}
      {isModalVisible2 && (
        <ModalToChangePassword
          visible={isModalVisible2}
          onOk={handleOkModal2}
          onCancel={handleCancelModal2}
          loading={loading}
        />
      )}

      {/* [팝업] 비밀번호 수정 완료 */}
      {isModalVisible3 && (
        <Modal
          title="비밀번호 수정 완료"
          okText="소보로 로그인"
          onOk={handleOkModal3}
          onCancel={handleCancelModal3}
        >
          <p>
            새로운 비밀번호로 변경하였습니다.
            <br />
            다시 로그인해주세요.
          </p>
        </Modal>
      )}

      {/* [팝업] 회원 탈퇴 */}
      {withdrawalModalVisible && (
        <ModalToWithdrawal
          email={email || ''}
          visible={withdrawalModalVisible}
          onOk={handleOkWithdrawalModal}
          onCancel={handleCancelWithdrawalModal}
          loading={loading}
        />
      )}

      <AccountErrorModal
        visible={errorModalVisible}
        onCancel={closeErrorModal}
        onOk={closeErrorModal}
        title={errorMessage.title}
        content={errorMessage.content}
      />
    </>
  );
}

export default Account;

const styleAccountInfo = css`
  margin: 3.2rem 0;

  dl {
    display: flex;
  }

  dl:nth-child(1) {
    margin-bottom: 1.6rem;
  }

  dt {
    margin-right: 2rem;
  }

  .title {
    color: ${theme.color.tertiary.default};
  }
`;

const styleAccountWrapButtons = css`
  border-top: 1px solid #d5dde7;
  padding-top: 3.2rem;

  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 1rem;
    padding-left: 0;
    color: #191919;
  }
`;
