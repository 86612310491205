import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';
import { media } from '@/assets/styles/GlobalStyles';
import styled from '@emotion/styled';

export const styledLayoutWrap = css`
  height: 100%;
  background-color: #fff;
`;

/** LayoutAside **/
const layoutAsideWidth = '24rem';

export const styledLayoutHeader = css`
  padding: 4.6rem 3rem 1rem;
`;

export const styledLayoutAside = css`
  width: ${layoutAsideWidth};
  padding: 0;
  border-right: 1px solid ${theme.color.line};
  color: #141414;
  background-color: #fcfdff;

  .btn-gnb-close {
    display: none;
  }
  ${media.pc} {
    &.isHidden {
      margin-left: -${layoutAsideWidth};
    }
    &:hover {
      .btn-gnb-close {
        display: block;
      }
    }
  }

  &.mobile {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;

    &.isHidden {
      transform: translateX(-${layoutAsideWidth});
    }
  }
`;

export const styledLayoutGnb = css`
  overflow: hidden;
  overflow-y: auto;
  padding: 1rem 2rem;
`;

export const styledLayoutAsideDim = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(32, 32, 32, 0.2); ;
`;

/** LayoutBody **/
export const styledLayoutContentWrap = css`
  overflow: hidden;

  ${media.mobile} {
    overflow: scroll;
  }
`;

/** LayoutBodyMain **/
export const styledLayoutContent = css`
  overflow: hidden;
  overflow-y: auto;

  ${media.pc} {
    padding: 0 3rem;
  }

  ${media.mobile} {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .detail & {
    padding: 0;
  }

  .infinite & {
    padding: 0;

    .ant-list {
      ${media.pc} {
        padding: 0 3rem;
      }

      ${media.mobile} {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
`;

/** LayoutCenterAlign **/
export const StyledWrapLayoutCenterAlign = styled('div')`
  overflow: auto;
  height: 100%;
`;

export const StyledLayoutCenterAlignHeader = styled('header')((props) => {
  return `
    width: 100%;
    // outline: 1px solid red;
    padding: 4rem 0 0 4rem;
    
   ${media.mobile} {
      padding-left: 2rem;
    }
  `;
});

export const layoutCenterAlignPCMinWidth = 360 - 1;

export const StyledLayoutCenterAlign = styled('div')`
  margin: auto;
`;
export const StyledLayoutCenterAlignInner = styled('section')`
  width: calc(32rem + 4rem);
  padding: 0.9rem 2rem calc(0.9rem + 4rem + 1.6rem);

  ${media.custom(layoutCenterAlignPCMinWidth)} {
    width: 100%;

    .line-break {
      display: inline;
    }
  }
`;

interface StyledPageHeaderProps {
  hideNav?: boolean;
  mobileSize?: boolean;
}

/** 스타일 **/
export const StyledPageHeader = styled('header')((props: StyledPageHeaderProps) => {
  return `
      ${
        props.hideNav
          ? `
          position: relative;
          padding-left: 3rem;
        
          .btn-open-aside {
            position: absolute;
            top: 0;
            left: -1rem;
          }
        `
          : `
          .btn-open-aside {
            display: none;
          }
        `
      }
      
      overflow: hidden;
   
      .title {
        font-weight: 700;
        font-size: 2rem;
        line-height: 128.9%;
        text-align: left;
      }
      
      h1.title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
  `;
});

export const styledPageHeader = css`
  border-bottom: 1px solid ${theme.color.line};
  padding: 3rem 3rem 2.9rem;

  .layout-body:not(.detail) & {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-left: 0;
    padding-right: 0;
  }

  ${media.mobile} {
    padding-left: 2rem;
    padding-right: 2rem;

    .layout-body:not(.detail) & {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  .wrap-aside {
    ${media.pc} {
      margin-left: 4rem;
    }
  }
`;
