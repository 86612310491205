import AntdModal, { ModalProps } from 'antd/lib/modal/Modal';
import * as React from 'react';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import Button from '@/components/button/Button';
import Loading from '@/components/loading/Loading';

interface TModalProps extends ModalProps {
  loading?: boolean;
  isButtonDisabled?: boolean;
  type?: 'primary' | 'secondary';
}

/**
 * - `onCancel` 이 있어야 `maskClosable=true` 가 적용되어, 마스크(모달 외부 영역)를 클릭시 모달 상자가 닫힘.
 * */
const Modal = (props: TModalProps) => {
  const {
    children,
    className,
    title,
    visible = true,
    loading,
    width,
    okText,
    cancelText,
    onOk,
    onCancel,
    type = 'primary',
    isButtonDisabled,
    ...rest
  } = props;

  const OkButton = useMemo(() => {
    if (!okText) {
      return null;
    }
    return (
      <Button
        design="modal1"
        loading={loading}
        onClick={onOk}
        key="onOk"
        disabled={isButtonDisabled}
      >
        {okText}
      </Button>
    );
  }, [okText, loading, onOk, isButtonDisabled]);

  const CancelButton = useMemo(() => {
    if (!cancelText) {
      return null;
    }
    return (
      <Button design="modal2" onClick={onCancel} key="onCancel">
        {cancelText}
      </Button>
    );
  }, [cancelText, onCancel]);

  return (
    <StyledModal
      type={type}
      title={title}
      visible={visible}
      centered={true}
      width={width || '320px'}
      closeIcon={true}
      onOk={onOk}
      onCancel={onCancel}
      footer={[CancelButton || null, OkButton]}
      className={`${className || ''}`}
      transitionName="none"
      maskTransitionName="none"
      {...rest}
    >
      {loading && <Loading layer="absolute" tip="" />}
      {children}
    </StyledModal>
  );
};

export default Modal;

const StyledModal = styled(AntdModal)((props: { type: TModalProps['type'] }) => {
  const { type } = props;

  let headerPaddingTop = '3.2rem';
  let modalBodyPaddingBottom = '3.2rem';
  let textAlign = 'center';

  if (type === 'secondary') {
    headerPaddingTop = '2rem';
    modalBodyPaddingBottom = '2rem';
    textAlign = 'left';
  }

  return `
    &.ant-modal {
      margin: 1rem auto;
    }
    
    .ant-modal-content {
      border-radius: 6px;
    }
    
    .ant-modal-header {
      padding: ${headerPaddingTop} 2rem 0;
      border: none;
      border-radius: 6px 6px 0 0;
      text-align: ${textAlign}
    }
    
    .ant-modal-title {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 128.9%;
    }
    
    .ant-modal-body {
      padding: 2.4rem 2rem ${modalBodyPaddingBottom};
      text-align: ${textAlign}
    }
    
    .ant-modal-footer {
       padding: 0 2rem 2rem;
       border: none;
       border-radius: 0 0 6px 6px;
       text-align: center;
       
       button {
        width: calc(50% - 1rem);
        
          &:only-child {
            width: 100%;
          }
       }
       
       button + button {
          margin-left: 2rem;
       }
    }
  `;
});
