import useMutationWithMessage from '@/hooks/useMutationWithMessage';
import apiClient from '@/lib/api/apiClient';

interface IWithdrawParam {
  reason: string[];
  content: string | undefined;
}

export default function useWithdrawMutation() {
  const withdraw = async ({ reason, content }: IWithdrawParam) => {
    const withdrawReason = reason ? reason : [];

    const { data } = await apiClient.put(`/withdraw`, {
      reason: withdrawReason,
      ...(content && { content }),
    });
    return data;
  };

  return useMutationWithMessage(withdraw, '소보로를 탈퇴했습니다.');
}
