import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';

/** Text Field **/
export const styledTextField2 = css`
  font-size: 1.2rem;
  line-height: 15.47px;
  color: inherit;
  word-break: break-word;

  .email {
    color: ${theme.color.primary.default};
  }

  &.notice {
    color: #191919;
  }
`;

export const styledTextField1 = css`
  display: inline-block;
  margin-bottom: 1.4rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 24px;
  color: inherit;
`;

/** Headline **/
export const styledHeadline1 = css`
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 160%;
  color: inherit;
`;

/** Title **/
export const styledTitle1 = css`
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 128.9%;
  color: inherit;
`;

export const styledTitle2 = css`
  font-size: 2rem;
  font-weight: 700;
  line-height: 128.9%;
  color: inherit;
`;

export const styledTitle3 = css`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 128.9%;
  color: inherit;
`;

export const styledTitle4 = css`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 128.9%;
  color: inherit;
`;
