import { PasswordProps } from 'antd/lib/input/Password';
import { Input } from 'antd';
import React, { forwardRef } from 'react';
import { StyledTextInput } from '@/components/inputText/styleOfInput';

interface TInternalProps extends PasswordProps {
  isError?: boolean;
}

const InputPassword = forwardRef<any, TInternalProps>((props, ref) => {
  const {
    isError,
    visibilityToggle = false,
    className,
    placeholder,
    allowClear,
    showCount,
    maxLength,
    ...rest
  } = props;

  let { status } = props;

  if (isError) {
    status = 'error';
  }

  return (
    <>
      <StyledPasswordInput
        ref={ref}
        visibilityToggle={visibilityToggle}
        placeholder={placeholder}
        allowClear={allowClear}
        showCount={showCount}
        maxLength={maxLength}
        className={`${className || ''}`}
        {...rest}
        status={status}
      />
    </>
  );
});
export default InputPassword;

const StyledPasswordInput = StyledTextInput.withComponent(Input.Password);
