import React, { useCallback, useEffect } from 'react';
import { Checkbox as AntdCheckbox, Form } from 'antd';
import Modal from '@/components/popup/Modal';
import Checkbox from '@/components/checkbox/Checkbox';
import TextArea from '@/components/textArea/TextArea';
import { styledTextField2 } from '@/assets/styles/styledText';
import { css } from '@emotion/react';

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface TModalToWithdrawalProps {
  visible: boolean;
  onOk: (values: Values) => void;
  onCancel: () => void;
  email: string;
  loading?: boolean;
}

const ModalToWithdrawal: React.FC<TModalToWithdrawalProps> = ({
  visible,
  onOk,
  onCancel,
  email,
  loading = false,
}) => {
  const [form] = Form.useForm();
  const agreement = Form.useWatch('agreement', form);
  const reason = Form.useWatch('reason', form);

  useEffect(() => {
    if (reason && !reason.includes('etc')) {
      form.resetFields(['content']);
    }
  }, [reason, form]);

  const handleOkModal = useCallback(async () => {
    try {
      const values = await form.validateFields();
      onOk(values);
      form.resetFields();
    } catch (error) {
      console.log('Validate Failed:', error);
    }
  }, [form, onOk]);

  const handleCancelModal = useCallback(() => {
    onCancel();
    form.resetFields();
  }, [onCancel, form]);

  return (
    <Modal
      type="secondary"
      visible={visible}
      data-visible={visible}
      title="소보로 회원 탈퇴"
      okText="탈퇴하기"
      cancelText="취소"
      onCancel={handleCancelModal}
      onOk={handleOkModal}
      isButtonDisabled={!agreement}
      loading={loading}
    >
      <section css={styledTextField2}>
        <h1 className="font-bold">▪︎ 회원 탈퇴를 신청하기 전에 안내사항을 꼭 확인해주세요.</h1>
        <p className="mt-[1.6rem]">
          • 탈퇴 후 회원정보 및 구매정보, 자막 저장기록은 모두 삭제되며, 삭제된 데이터는 복구되지
          않습니다.
          <br />
          • 삭제되는 내용을 확인하시고 필요한 데이터는 미리 백업을 해주세요.
          <br />
        </p>
        <p>
          • 탈퇴 후에는 아이디 <span className="email">{email}</span> 으로 다시 가입할 수 없으며
          아이디와 데이터는 복구할 수 없습니다.
        </p>
      </section>

      <section className="mt-[1.6rem]" css={styledTextField2}>
        <h6 className="font-bold">▪︎ 소보로 탈퇴 이유를 선택해 주세요. (복수선택 가능)</h6>
        <Form
          form={form}
          name="formInModal"
          layout="vertical"
          requiredMark={false}
          autoComplete="off"
        >
          <fieldset css={styledFieldset}>
            <Form.Item name="reason" css={styledcheck}>
              <AntdCheckbox.Group>
                <Checkbox value="service">이용할만한 기능/서비스 부족</Checkbox>
                <Checkbox value="info">개인정보 노출 우려</Checkbox>
                <Checkbox value="quality">소보로 품질에 대한 불만(잦은 오류, 오작동 등)</Checkbox>
                <Checkbox value="restriction">제재조치로 이용 제한됨</Checkbox>
                <Checkbox value="delete">저장 자막 삭제 목적</Checkbox>
                <Checkbox value="temporary">강의나 세미나 등의 목적으로 한시 사용</Checkbox>
                <Checkbox value="etc">기타</Checkbox>
              </AntdCheckbox.Group>
            </Form.Item>
            {reason && reason.includes('etc') && (
              <Form.Item name="content">
                <TextArea
                  rows={4}
                  placeholder={'탈퇴 이유를 구체적으로 적어주세요. (최대 1000자)'}
                  maxLength={1000}
                  css={styledTextField2}
                  className="border-0"
                />
              </Form.Item>
            )}
          </fieldset>

          <Form.Item
            name="agreement"
            css={styledcheck}
            className="mt-[1rem]"
            valuePropName="checked"
          >
            <Checkbox className="text-medium">
              안내 사항을 모두 확인하였으며, 이에 동의합니다.
              <em className="required-mark">(필수)</em>
            </Checkbox>
          </Form.Item>
        </Form>
      </section>
    </Modal>
  );
};

export default ModalToWithdrawal;

const styledFieldset = css`
  margin-top: 1.6rem;
  padding: 1.2rem;
  background: #f3f5f8;
  border-radius: 6px; ;
`;

const styledcheck = css`
  .ant-checkbox-wrapper {
    font-size: 1.2rem;
    line-height: 128.91%;
    align-items: start;

    &:not(:nth-of-type(1)) {
      margin-top: 7px;
    }

    &:after {
      display: none;
    }

    .ant-checkbox {
      top: 0;
    }
  }
`;
