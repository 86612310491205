import React, { lazy, useCallback, useEffect, useState, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import useAuthStateChanged from '@/hooks/useAuthStateChanged';
import useNetworkMonitoring from '@/hooks/useNetworkMonitoring';
import useRouteChangeTracker from '@/hooks/useRouteChangeTracker';
import NetworkStatusAlert from '@/components/popup/NetworkStatusAlert';
import Loading from '@/components/loading/Loading';
import AppLayout from '@/components/app/AppLayout';
import Account from '@/pages/Account';
import UsageState from '@/pages/UsageState';
import { useSetRecoilState } from 'recoil';
import { isMobileState } from '@/atoms/isMobileState';
import { hideNavState } from '@/atoms/navState';
import { messageInfo } from '@/components/popup/message';

const SignIn = lazy(() => import('@/pages/SignIn'));
const RequestPasswordReset = lazy(() => import('@/pages/RequestPasswordReset'));
const SignUpStep1 = lazy(() => import('@/pages/SignUpStep1'));
const SignUpStep2 = lazy(() => import('@/pages/SignUpStep2'));
const Home = lazy(() => import('@/pages/Home'));
const Notes = lazy(() => import('@/pages/Notes'));
const NoteDetail = lazy(() => import('@/pages/NoteDetail'));
const Trash = lazy(() => import('@/pages/Trash'));
const TermsAndConditions = lazy(() => import('@/pages/TermsAndConditions'));
const NotFound = lazy(() => import('@/pages/Notfound'));
const UnsupportedBrowser = lazy(() => import('@/pages/UnsupportedBrowser'));
const EmailActionResult = lazy(() => import('@/pages/EmailActionResult'));
const PasswordReset = lazy(() => import('@/pages/PasswordReset'));
const Upload = lazy(() => import('@/pages/Upload'));
const Notice = lazy(() => import('@/pages/Notice'));

function App() {
  useAuthStateChanged();
  useRouteChangeTracker();
  const onLine = useNetworkMonitoring();

  const [isAndroid, setIsAndroid] = useState(false);

  const setIsMobile = useSetRecoilState(isMobileState);

  const setHideNav = useSetRecoilState(hideNavState);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!onLine) {
        messageInfo({
          content: '오프라인 상태에서는 이용하실 수 없습니다.',
          noIcon: true,
        });
      }
    };

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, [onLine]);

  useEffect(() => {
    const isMobileAgent = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
    setIsMobile(isMobileAgent);

    setIsAndroid(/Android/i.test(window.navigator.userAgent));

    if (isMobileAgent) {
      setHideNav(true);
    }
  }, [setHideNav, setIsAndroid, setIsMobile]);

  const [appModalVisible, setAppModalVisible] = useState(true);
  const handleAppModal = useCallback(() => {
    setAppModalVisible(!appModalVisible);
  }, [appModalVisible]);

  return (
    <>
      {!onLine && <NetworkStatusAlert />}
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* public */}
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/sign-up/step/1" element={<SignUpStep1 />} />
          <Route path="/sign-up/step/2" element={<SignUpStep2 />} />

          {/* private */}
          <Route path="/" element={<AppLayout />}>
            <Route path="home" element={<Home />} />
            <Route path="notes">
              <Route index element={<Notes />} />
              <Route path=":noteId" element={<NoteDetail />} />
              <Route path=":noteId/:mode" element={<NoteDetail />} />
            </Route>
            <Route path="usage-state" element={<UsageState />} />
            <Route path="trash-notes">
              <Route index element={<Trash />} />
              <Route path=":noteId" element={<NoteDetail />} />
            </Route>
            <Route path="terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="account" element={<Account />} />
            <Route path="upload" element={<Upload />} />
            <Route path="notice">
              <Route index element={<Notice />} />
              <Route path=":notionId" element={<Notice />} />
            </Route>
          </Route>

          <Route path="action-result" element={<EmailActionResult />} />
          <Route path="password-reset" element={<PasswordReset />} />

          {/* 지원하지 않는 브라우저 접속시 */}
          <Route path="unsupported-browser" element={<UnsupportedBrowser />} />

          {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      {/*<DemoRoute />*/}
      {/* TODO 모바일에서 웹으로 접근 */}
      {/*{isAndroid && appModalVisible && (*/}
      {/*  <Modal*/}
      {/*    title="소보로 앱으로 접속하시겠어요?"*/}
      {/*    okText="앱으로 접속"*/}
      {/*    cancelText="아니오"*/}
      {/*    onOk={handleAppModal}*/}
      {/*    onCancel={handleAppModal}*/}
      {/*  >*/}
      {/*    <p>소보로 모바일 앱에서도 사용하실 수 있어요.</p>*/}
      {/*  </Modal>*/}
      {/*)}*/}
    </>
  );
}

export default App;
