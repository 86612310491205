import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';
import styled from '@emotion/styled';
import { Input } from 'antd';
import { TInputProps } from '@/components/inputText/InputText';
import { TTextAreaProps } from '@/components/textArea/TextArea';

export const defaultStyleOfInput = css`
  border-radius: ${theme.formRadius.input};
  font-size: 1.4rem;
  box-shadow: none !important;
`;

export const defaultStyleOfInputElement = css`
  color: ${theme.color.default};
  box-shadow: none !important;

  &::placeholder {
    color: ${theme.color.tertiary.default};
  }
`;

// .ant-input-affix-wrapper-status-error .ant-input
//  .ant-input.ant-input-status-error

export const StyledTextInput = styled(
  Input,
  {},
)((props: Pick<TInputProps, 'status' | 'allowClear' | 'showCount'>) => {
  let borderColor = theme.formColor.default;
  const { status, allowClear, showCount } = props;
  const isWrapper = !!allowClear || !!showCount;

  if (status === 'error') {
    borderColor = theme.formColor.error;
  }

  const styles = [];

  const defaultStyle = css`
    ${defaultStyleOfInput};
    border-color: ${borderColor};
  `;

  styles.push(defaultStyle);

  const styleOfInputElement = css`
    ${defaultStyleOfInputElement};
    text-align: center;

    &:hover {
      border-color: ${borderColor};
    }

    &:focus {
      border-color: ${theme.formColor.focus};
    }
  `;

  if (isWrapper) {
    // &.ant-input-affix-wrapper .ant-input
    const wrappedInput = css`
      .ant-input {
        ${styleOfInputElement};
        height: auto;
      }

      .ant-input-clear-icon svg[data-icon='close-circle'] {
        width: 1.8rem;
        height: 1.8rem;
        fill: ${theme.formColor.icon};
      }

      &.ant-input-affix-wrapper {
        height: 4.8rem;
      }

      &.ant-input-affix-wrapper:hover,
      &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover,
      &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: ${borderColor};
      }

      &.ant-input-affix-wrapper-focused,
      &.ant-input-affix-wrapper-focused:hover,
      &.ant-input-affix-wrapper-focused:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: ${theme.formColor.focus};
      }
    `;

    styles.push(wrappedInput);
  } else {
    // &.ant-input
    const input = css`
      &.ant-input {
        ${styleOfInputElement};
        height: 4.8rem;
      }
    `;
    styles.push(input);
  }

  return css`
    ${styles}
  `;
});

export const StyledTextArea = styled(
  Input.TextArea,
  {},
)((props: Pick<TTextAreaProps, 'status' | 'allowClear' | 'showCount' | 'className'>) => {
  const { status, allowClear, showCount, className } = props;
  const isWrapper = !!allowClear || !!showCount;
  let borderColor = theme.formColor.default;
  let focusColor = theme.formColor.focus;
  let bgColor = 'transparent';


  if (className) {
    if (className.includes('border-0')) {
      borderColor = 'transparent';
      focusColor = 'transparent';
      bgColor = '#F9F9FA';
    }
  }

  if (status === 'error') {
    borderColor = theme.formColor.error;
  }

  const styles = [];

  const defaultStyle = css`
    ${defaultStyleOfInput};
    border-color: ${borderColor};
  `;

  styles.push(defaultStyle);

  const styleOfInputElement = css`
    ${defaultStyleOfInputElement};
    background-color: ${bgColor};

    &:hover {
      border-color: ${borderColor};
    }

    &:focus {
      border-color: ${focusColor};
    }
  `;

  if (isWrapper) {
    // &.ant-input-affix-wrapper .ant-input
    const wrappedInput = css`
      .ant-input {
        ${styleOfInputElement};
      }
    `;

    styles.push(wrappedInput);
  } else {
    // &.ant-input
    const input = css`
      &.ant-input {
        ${styleOfInputElement};
      }
    `;
    styles.push(input);
  }

  return css`
    ${styles}
  `;
});
