import { Link } from 'react-router-dom';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { hideNavState } from '@/atoms/navState';
import { isMobileState } from '@/atoms/isMobileState';

const GnbItem = (
  { to, name, icon, className }: { to: string; name: string; icon: string; className?: string },
  ref: any,
) => {
  const setHideNav = useSetRecoilState(hideNavState);
  const [isMobile] = useRecoilState(isMobileState);

  const onClick = () => {
    if (isMobile) {
      setHideNav(true);
    }
  };

  const IconE = useMemo(() => {
    return (
      <>
        <span className="icon">
          <img src={icon} alt={`${name} 아이콘`} />
        </span>
        <span className="text">{name}</span>
      </>
    );
  }, [icon, name]);

  return (
    <StyledGnbItem className={className || ''}>
      {!to.includes('mailto:') ? (
        <Link to={to} ref={ref} className="menu-link" onClick={onClick}>
          {IconE}
        </Link>
      ) : (
        <a href={to} ref={ref} className="menu-link">
          {IconE}
        </a>
      )}
    </StyledGnbItem>
  );
};

export default React.forwardRef(GnbItem);

const StyledGnbItem = styled('div')`
  &.is-active {
    .menu-link {
      background-color: #dee6ff;
    }
  }

  .menu-link {
    min-height: 4rem;
    margin-bottom: 0.3rem;
    padding: 1rem 1.2rem;
    border-radius: 0.6rem;
    display: block;

    &:not(.no-hover):hover {
      background-color: #f0f4ff;
    }
  }

  .icon {
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
  }

  .text {
    display: inline-block;
    vertical-align: middle;
  }
`;
