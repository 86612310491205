import { css } from '@emotion/react';

export const antdStyleMessage = css`
  .ant-message {
    top: auto;
    bottom: 3.2rem;
  }

  .ant-message-notice.custom {
    box-shadow: none;

    .ant-message-notice-content {
      border-radius: 2rem;
      color: #fff;
      background: rgba(14, 15, 15, 0.8);
    }

    &.no-icon {
      span[role='img'] {
        display: none;
      }
    }
  }
`;

export const antdStyleTooltip = css`
  position: relative;
`;
